import { nextTick, watch, ref, onMounted, onUnmounted } from 'vue'
import { debounce } from '@shein/common-function'

// 计算两个 dom 是否换行了
const checkTwoDomWrap = (mainDom, wrapDom) => {
  const mainDomTop = mainDom?.getBoundingClientRect?.().top
  const wrapDomTop = wrapDom?.getBoundingClientRect?.().top
  const mainDomHeight = mainDom?.getBoundingClientRect?.().height
  return (mainDomTop + mainDomHeight) <= wrapDomTop + 3
}

// 换行元素前面插入一个元素
const handleLineBreaksInContainer = (quickPriceDom) => {

  // 移除之前插入的占位节点
  for (const item of quickPriceDom.children) {
    if (item.dataset.isInserDom) {
      quickPriceDom.removeChild(item)
    }
  }

  // 获取每个元素的顶部和底部的页面位置
  // 头部位置低于前一个元素的底部位置那说明当前元素发生换行
  let currentDom = {}
  let preDom = {}
  const needInsertDom = []
  for (const item of quickPriceDom.children) {
    const top = item.getBoundingClientRect().top
    const height = item.offsetHeight
    const bottom = top + height
    currentDom = {
      top,
      bottom,
    }
    if (preDom.top && currentDom.top >= preDom.bottom && !item.dataset.noInsert && height) {
      needInsertDom.push(item)
    }
    preDom = currentDom
  }
  if (needInsertDom.length === 0) return
  // 在换行的元素前插入一个width 100% height .1333rem 的 div
  console.log('needInsertDom', needInsertDom)
  for (const item of needInsertDom) {
    const div = document.createElement('div')
    div.style.width = '100%'
    div.style.height = '.1067rem'
    div.dataset.isInserDom = '1'
    if (item.dataset.wrapHeight) div.style.height = item.dataset.wrapHeight
    quickPriceDom.insertBefore(div, item)
  }
}

const useCalcDomFn = () => {

  /* 换行改变到手价和折扣气泡小三角位置计算 */
  const discountInfoIsWrap = ref(-1)
  const estiamtedTagIsWrap = ref(-1)
  const calcuDiscountInfoIsWrap = (mainPriceDom, discountInfoDom) => {
    if (!discountInfoDom) return
    discountInfoIsWrap.value = Number(checkTwoDomWrap(mainPriceDom, discountInfoDom))
  }
  const calcuEstiamtedTagIsWrap = (mainPriceDom, estimatedTagDom) => {
    if (!estimatedTagDom) return
    estiamtedTagIsWrap.value = Number(checkTwoDomWrap(mainPriceDom, estimatedTagDom))
  }
  /* 换行改变到手价和折扣气泡小三角位置计算 */

  const calcDomFn = debounce({
    func: () => {
      const mainPriceDom = document.getElementById('quickMainPriceId')
      handleLineBreaksInContainer(document.getElementById('quickPriceId'))
      calcuDiscountInfoIsWrap(mainPriceDom, document.getElementById('quickDiscountInfoId'))
      calcuEstiamtedTagIsWrap(mainPriceDom, document.getElementById('quickEstimatedTagId'))
      domUpdateObserver?.disconnect?.()
      domUpdateObserver = null
    },
    wait: 1000,
  })
  let domUpdateObserver = new MutationObserver(() => {
    calcDomFn()
  })

  onMounted(async () => {
    await nextTick()
    const quickPriceDom = document.getElementById('quickPriceId')
    if (!quickPriceDom) return
    if (domUpdateObserver?.observe) {
      domUpdateObserver.observe(quickPriceDom, {
        childList: true,
      })
    } else {
      domUpdateObserver = new MutationObserver(() => {
        calcDomFn()
      })
      domUpdateObserver.observe(quickPriceDom, {
        childList: true,
      })
    }
    window.appEventCenter?.$on?.('quickDetailcalcPriceDomFn', () => {
      if (domUpdateObserver) return
      calcDomFn()
    })
  })

  onUnmounted(() => {
    domUpdateObserver?.disconnect?.()
    window.appEventCenter?.$off?.('quickDetailcalcPriceDomFn')
  })

  return {
    discountInfoIsWrap,
    estiamtedTagIsWrap,
  }
}


export default useCalcDomFn
